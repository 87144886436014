@import './vars';
@import './normalize';
@import './global';
@import './mixins';
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';
@import './fonts';

// Header
.header {
    position: fixed;
    width: 100%;
    padding: 30px 0;
    background: #FEFEFE;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.2);
    top: 0;
    z-index: 9999;
    &__col-1 {
        @include col(1);
    }
    &__col-7 {
        @include col(7);
    }
    &__col-3 {
        @include col(3);
    }
    &__nav {
        padding-left: 30px;
        padding-right: 30px;
    }
    &__nav, &__ul {
        height: 100%;
        width: 100%;
    }
    &__ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            @include font($default);
            @include text (20px, 500, $dark, none);
        }
    }
    &__a {
        &-active {
            font-weight: 700 !important;
        }
    }
    &__input {
        border: none;
        outline: none;
        padding-top: 3px;
        &::placeholder {
            @include font ($default);
            @include text (20px, 500, $dark, capitalize);
        }
        &-box {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: center;
            a {
                background-image: url(../img/loupe.svg);
                @include bci();
                width: 16px;
                height: 16px;
                margin-right: 10px;
            }
            &-hidden {
                transition: all 0.3s ease;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                z-index: 9999999999999999;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                padding: 49px 214px 59px 252px; 
                background-color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input {
                    width: 600px;
                    height: 50px;
                    border: none;
                    outline: none;
                    &::placeholder {
                        @include text (36px, 300, rgba(71, 70, 70, 0.5), none);
                        @include font ($default);
                    }
                }
                button {
                    background-image: url(../img/cancel.svg);
                    @include bci();
                    width: 18px;
                    height: 18px;
                }
            }
        }
    }
}
// Banner
.banner, .company, .catalog, .furniture, .contact {
    margin-top: 150px;
}   
.banner {
    padding-top: 59px;
    background: linear-gradient(97.84deg, #51D0FF 4.29%, #5388F1 54.1%);
    position: relative;
    &:before {
        content: '';
        width: 80%;
        height: 8px;
        border-radius: 50px;
        background-color: #62C6FB;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &__item {
        width: 100%;
        display: flex !important;
        align-items: center;
    }
    &__title-box {
        margin-right: 77px;
        h2 {
            @include text (48px, 700, #FEFEFE, uppercase);
            max-width: 640px;
            margin-bottom: 46px;
            line-height: 60px;
        }
        button {
            background: #FEFEFE;
            border-radius: 10px;
            filter: drop-shadow(20px 30px 30px rgba(0, 0, 0, 0.3));
            outline: none;
            border: none;
            padding: 27px 53px;
            @include text (20px, 700, #064BB2, uppercase);
        }
        h2, button {
            @include font ($default);
        }
    }
    &__dots {
        bottom: 0;
        left: 50%;
    }
    .slick {
        &-dots {
            position: absolute;
            display: flex;
            left: 50%;
            li {
                margin-right: 17px;
                button {
                    font-size: 0;
                    background: #FFFFFF;
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .slick-active {
                button {
                    background: #5388F1;
                }
            }
        }
    }
}

#banner__spc {
    margin-bottom: 25%;
}
.none {
    display: none;
}
.section {
    &__title-box {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        h3 {
            @include font ($default);
            @include text (36px, 700, #344F5A, uppercase);
            margin-bottom: 15px;
        }
        p {
            @include font ($alternative);
            @include text (20px, 500, #344F5A, none);
        }
        &-left {
            margin: 0 0;
            text-align: left;
        }
    }
}
.materials {
    padding: 105px 0 133px;
    .section__title-box {
        margin-bottom: 65px;
    }
    &__col-3 {
        @include col(3);
    }
    &__box {
        width: 100%;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
            filter: drop-shadow(0px 4px 40px rgba(136, 136, 136, 0.5));
            transform: scale(1.1);
        }
        &-img {
            margin-bottom: -4px;
            img {
                width: 100%;
            }
        }
        &-text {
            padding: 30px 0;
            text-align: center;
            background: #FEFEFE;
            box-shadow: 10px 10px 60px rgba(138, 138, 138, 0.3);
            border-radius: 0px 0px 0px 40px;
            h3 {
                @include font ($default);
                @include text (26px, 800, #344F5A, uppercase);
            }
        }
    }
}
.offer {
    background: linear-gradient(244.39deg, rgba(164, 230, 255, 0.5) 16.22%, rgba(83, 136, 241, 0.5) 84.03%);
    padding: 94px 0;
    .section__title-box {
        margin-bottom: 42px;
    }
    .row {
        justify-content: center;
    }
    &__col-spc {
        position: relative;
    }
    &__col-4 {
        @include col(4);
    }
    &__col-10 {
        @include col(10);
        position: relative;
    }
    &__item {
        display: flex;
    }
    &__box {
        &-big {
            max-width: 60%;
            text-align: center;
            background-color: white;
            padding: 56px 117px 48px;
            background: #FEFEFE;
            border-radius: 20px 20px 0 0;
            span {
                @include font ($default);
                @include text (25px, 700, $dark, uppercase);
            }
        }
        &-small {
            padding: 49px 0 35px;
            width: 100%;
            text-align: center;
            border: 1px solid #E4E4E4;
            display: flex;
            flex-direction: column;
            background: #FEFEFE;
            img {
                margin: 0 auto 10px;
                width: 60px;
            }
            span {
                @include font ($default);
                @include text (20px, 700, $dark, uppercase);
            }
        }
    }
    &__big {
        &-spc {
            max-width: none;
            width: 50%;
            position: absolute;
            background-color: #F1F1F1;
            padding: 56px 0 48px;
            right: 15px;
            bottom: -10px;
            z-index: 0;
            text-align: right;
            padding-right: 82px;
        }
        &-left {
            position: relative;
            z-index: 2;
        }
    }
}
.equipment {
    padding: 106px 0 48px;
    .section__title-box {
        max-width: 896px;
        margin-bottom: 56px;
        h3 {
            margin-bottom: 23px;
        }
    }
    img {
        width: 100%;
        height: 100%;
    }
    .slick {
        &-track {
            display: flex;
            align-items: center;
        }
        &-dots {
            position: absolute;
            display: flex;
            left: 50%;
            li {
                margin-right: 17px;
                button {
                    font-size: 0;
                    background: #FFFFFF;
                    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .slick-active {
                button {
                    background: #5388F1;
                }
            }
        }
        &-arrow {
            width: 70px;
            height: 70px;
            background-image: url(../img/slick-arrow-left.svg);
            @include bci();
            content: none;
            &:before {
                content: none;
            }
        }
        &-next {
            right: -89px;
            background-image: url(../img/slick-arrow-right.svg);
        }
        &-prev {
            left: -89px;
        }
        &-center {
            transition: all 0.3s ease;
            transform: scale(1.1);
        }
    }
}
.advantages {
    background: linear-gradient(91.14deg, rgba(81, 208, 255, 0.5) 11.49%, rgba(255, 255, 255, 0.5) 108.27%);
    padding: 86px 0 96px;
    .row {
        justify-content: center;
    }
    &__col-10 {
        @include col(10);
    }
    .section__title-box {
        margin-bottom: 50px;
    }
    &__coop {
        display: flex;
        margin-bottom: 38px;
        align-items: center;
    }
    &__box {
        text-align: center;
        margin-right: 60px;
        img {
            margin-bottom: 16px;
        }
        p {
            @include text (25px, 700, $dark, none);
            @include font ($default);
            max-width: 270px;
        }
    }
}
.work {
    padding: 85px 0 103px;
    .row {
        justify-content: center;
    }
    .section__title-box {
        margin-bottom: 62px; 
    }
    &__col-10 {
        @include col(10);
    }
    &__box {
        display: flex;
    }
    &__process {
        max-width: 160px;
        text-align: center;
        &:last-child {
            max-width: 140px;
        }
        &-img {
            padding: 40px;
            border-radius: 50%;
            background: #64DAFF;
            &-box {
                display: flex;
            }
            margin: 0 15px 16px 15px;
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        &-text {
            p {
                @include text (20px, 700, $dark, none);
                @include font ($default);
            }
        }
    }
    &__items {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    &__colour {
        &-2 {
            background: #51C6FD;
        }
        &-3 {
            background: #52B4F9;
        }
        &-4 {
            background: #5298F4;
        }
        &-5 {
            background: #3874EC;
        }
    }
}
.about {
    padding-bottom: 49px;
    .row {
        justify-content: center;
    }
    &__col-10 {
        @include col(10);
    }
    &__box {
        background: #FEFEFE;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 60px 54px 70px 50px;
        display: flex;
        align-items: center;
    }
    &__info {
        max-width: 50%;
        margin-right: 50px;
        h4 {
            @include font ($default);
            @include text (36px, 700, $dark, none);
            letter-spacing: 0.15em;
            margin-bottom: 26px;
        }
        p {
            @include font ($default);
            @include text (20px, 500, $dark, none);
            margin-bottom: 22px;
            line-height: 24px;
        }
        button {
            border: none;
            outline: none;
            padding: 27px 44px;
            box-shadow: 10px 40px 20px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            background: #528EF2;
            @include text (20px, 700, white, uppercase);
            @include font ($default);
            text-align: center;
        }
    }
    &__video {
        width: 50%;
        iframe {
            width: 100%;
            height: 259px;
        }
    }
}
.footer {
    padding: 69px 0 49px;
    background-color: white;
    border-top: 1px solid #56BBF9;
    &__col-1 {
        @include col(1);
    }
    &__col-5 {
        @include col(5);
    }
    &__col-4 {
        @include col(4);
    }
    &__ul {
        display: flex;
        width: 100%;
        &-1 {
            flex-wrap: wrap;
            li {
                margin-right: 60px;
                margin-bottom: 30px;
            }
            a {
                @include text (20px, 400, $dark, none);
                @include font ($default);
                display: inline-block;
            }
        }
        &-2 {
            margin-bottom: 34px;
            justify-content: flex-end;
            li {
                border-radius: 50%;
                border: 1px solid $dark;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
                width: 50px;
                height: 50px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    &__last {
        &-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }
    }
    &__span {
        display: inline-block;
        @include text (20px, 400, $dark, none);
        @include font ($default);
        line-height: 34px;
    }
}
.modal {
    &__content {
        height: 700px;
        padding: 95px 40px 78px 65px;
        background-color: white;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99999999;
        visibility: hidden;
        opacity: 0;
        border-radius: 50px 0 0 0;
        transition: all 0.3s ease;
        &-short {
            display: flex;
            margin-bottom: 44px;
        }
        &-img {
            max-width: 35%;
            margin-right: 30px;
            max-height: 326px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-box {
            max-width: 75%;
            display: flex;
            flex-direction: column;
            h3 {
                @include text (36px, 700, #181818, uppercase);
                @include font ($default);
                margin-bottom: 64px;
            }
            a {
                margin-bottom: 28px;
                position: relative;
                &:before {
                    content: '';
                    @include bci();
                    position: absolute;

                }
            }
            span {
                font-size: 18px;
            }
            p {
                @include text (20px, 400, black, none);
                @include font ($default);
                line-height: 40px;
                max-width: 508px;
            }
        }
        &-link {
            @include text (20px, 700, #327D33, none);
            @include font ($default);
            padding-left: 24px;
            &:before {
                background-image: url(../img/phone.svg);
                width: 20px;
                height: 20px;
                left: 0;
                top: 0;
            }
            &-second {
                padding-left: 47px;
                @include text (20px, 400, black, none);
                @include font ($default);
                &:before {
                    background-image: url(../img/modal-telegram.svg);
                    width: 30px;
                    height: 31px;
                    left: 0;
                    top: -3px;
                }
            }
        }
        &-full {
            max-width: 788px;
        }
        button {
            width: 30px;
            height: 30px;
            position: absolute;
            background-image: url(../img/x.png);
            @include bci();
            right: 35px;
            top: 35px;
        }
    }
    &__ul {
        li {
            margin-bottom: 10px;
        }
        p {
            @include text (20px, 400, black, none);
            @include font ($default);
            line-height: 50px;
        }
    }
}
#offer__box-show {
    display: none;
}
.burger {
    &-menu {
        display: none;
    }
}

.burger-menu_button {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 30;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
  .burger-menu_button:hover .burger-menu_lines {
    filter: brightness(0.7);
  }
  
  .burger-menu_button:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
  
  .burger-menu_lines::before,
  .burger-menu_lines::after,
  .burger-menu_lines {
    position: absolute;
    width: 50px;
    height: 3px;
    background: #3874EC;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
  .burger-menu_lines {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .burger-menu_lines::before {
    content: '';
    top: -12px;
  }
  .burger-menu_lines::after {
    content: '';
    top: 12px;
  }
  
  
  .burger-menu_active .burger-menu_lines {
    background-color: transparent;
  }
  .burger-menu_active .burger-menu_lines::before {
    top: 0;
    transform: rotate(45deg);
  }
  .burger-menu_active .burger-menu_lines::after{
    top: 0;
    transform: rotate(-45deg);
  }
  
  .burger-menu_nav {
    padding-top: 120px;
    position: fixed;
    top: 0;
    z-index: 20;
    display: flex;
    flex-flow: column;
    height: 100%;
    background: #64DAFF;
    overflow-y: auto;
    right: -100%;
    -webkit-transition: 0.8s;
    -moz-transition: 0.8s;
    -o-transition: 0.8s;
    transition: 0.8s;
  }
  .burger-menu_active .burger-menu_nav {
    right: 0;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
  .burger-menu_link {
    padding: 18px 35px;
    font-family: $default;
    font-size: 18px;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 400;
    color: #52B4F9;
    border-bottom: 1px solid #fff;
  }
  .burger-menu_link:first-child {
    border-top: 1px solid #fff;
  }
  .burger-menu_link:hover {
    filter: brightness(0.9);
  }
  .burger-menu_overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }
  .burger-menu_active .burger-menu_overlay {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
.company {
    padding: 55px 0 170px; 
    .row {
        justify-content: center;
    }
    &__col-10 {
        @include col(12);
    }
    &__item {
        &-top {
            width: 100%;
            margin-bottom: 10px;
            span {
                display: inline-block;
                @include text (20px, 500, $dark, none);
                @include font ($default);
                &:first-child {
                    padding-right: 87px;
                    position: relative;
                    &:before {
                        content: '';
                        width: 74px;
                        height: 1px;
                        background-color: $dark;
                        position: absolute;
                        right: 9px;
                        top: 50%;
                    }
                }
            }
        }
        &-left {
            padding-top: 64px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            max-width: 50%;
            margin-right: 67px;
            h4 {
                @include text (36px, 700, $dark, none);
                margin-bottom: 46px;
            }
            h4, p {
                @include font ($default);
            }
            p {
                @include text (20px, 500, $dark, none);
                max-width: 566px;
                line-height: 40px;
            }
        }
        &-right {
            max-width: 50%;
            position: relative;
            img {
                width: 100%;
            }
            &:before {
                content: '';
                width: 155px;
                height: 144px;
                background-image: url(../img/company-1__before.svg);
                @include bci();
                position: absolute;
                left: -155px;
                top: 0;
            }
        }
        &-content {
            display: flex;
        }
    }
    &__text {
        padding: 50px 0;
        p {
            @include text (20px, 500, $dark, none);
            @include font ($default);
            line-height: 35px;
        }
        &-box {
            display: flex;
            align-items: center;
            &-1 {
                margin-bottom: 79px;
            }
            &-2 {
                margin-bottom: 61px;
            }
        }
        &-left {
            padding: 0;
            max-width: 50%;
            margin-right: 30px;
            p {
                max-width: 570px;
                line-height: 40px;
            }
        }
        &-right {
            max-width: 50%;
            img {
                width: 100%;
            }
        }
    }
}
#company__left {
    margin-right: 30px;
}
#company__right-none {
    margin-right: 0;
}
.catalog {
    .row {
        justify-content: center;
    }
    padding: 55px 0 120px;
    &__col-3 {
        @include col(3);
    }
    &__col-10 {
        @include col(12);
    }
    &__source {
        margin-bottom: 65px;
    }
    .section__title-box {
        h3 {
            @include text (38px, 700, $dark, capitalize);
        }
        margin-bottom: 41px;
    }
    &__body {
        .modal__content-short {
            margin-bottom: 0;
        }
        .modal__content-img {
            margin: 0;
            max-height: 100%;
        }
    }
    &__selectors {
        display: flex;
        margin-bottom: 65px;
    }
    &__selector {
        background: #FEFEFE;
        border: 1px solid #C9C9C9;
        border-radius: 8px;
        padding: 19px 91px 19px 26px;
        position: relative;
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
        span {
            @include text (26px, 500, $dark, capitalize);
            @include font ($default);
            display: inline-block;
        }
        &:before {
            content: '';
            background-image: url(../img/arrow-down.svg);
            @include bci();
            width: 30px;
            height: 30px;
            position: absolute;
            right: 57px;
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover {
            &:before {
                transform: matrix(1, 0, 0, -1, 0, 0);
                top: 30%;
            }
            .catalog__selector-content {
                visibility: visible;
                transform: translateY(120px);
                opacity: 1;
            }
        }
        &-content {
            visibility: hidden;
            top: -100%;
            opacity: 0;
            position: absolute;
            background: #FFFFFF;
            border: 1px solid #C9C9C9;
            border-radius: 8px;
            padding: 36px 52px 37px 57px;
            z-index: 399;
            .catalog__custom, label {
                display: inline-block;
            }
            li {
                margin-bottom: 20px;
            }
            label {
                @include text (20px, 500, $dark, none);
                @include font ($default);
                position: relative;
                margin-left: 3px;
            }
            label input:checked ~ .checkmark:after {
                display: block;
            }
            label .checkmark:after {
                left: 9px;
                top: 5px;
                width: 4px;
                height: 10px;
                border: solid black;
                border-width: 0 2px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
            .checkmark {
                position: absolute;
                top: 50%;
                left: -30px;
                height: 27px;
                width: 27px;
                background: #F1F1F1;
                border: 1px solid #C9C9C9;
                border-radius: 8px;
                transform: translateY(-50%);
                cursor: pointer;
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
        }
    }
    &__content {
        justify-content: flex-start !important;
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__item {
        padding: 117px 82px;
        text-align: center;
        border-radius: 10px;
        background-image: url(../img/catalog-item.jpg);
        @include bci();
        position: relative;
        height: 373px;
        span {
            @include text (30px, 700, #FEFEFE, uppercase);
            @include font ($default);
            display: inline-block;
            margin-bottom: 22px;
            position: relative;
        }
        button {
            background: transparent;
            @include text (20px, 500, white, capitalize);
            @include font ($default);
            padding: 12px 22px;
            border: 1px solid white;
            position: relative;
        }
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 10px;
        }
    }
}
.furniture {
    padding-top: 55px;
    .row {
        justify-content: center;
    }
    &__col-10 {
        @include col(12);
    }
    &__col-6 {
        @include col(6);
    }
    .catalog__source {
        margin-bottom: 65px;
    }
    .section__title-box {
        h3 {
            @include text (38px, 700, $dark, none);
        }
        margin-bottom: 65px;
    }
    &__tab {
        background: #F2F2F2;
        padding: 29px 0 27px;
        &-box {
            ul {
                display: flex;
            }
            li {
                width: 100%;
                margin-right: 2px;
                &:last-child {
                    margin-right: 0;
                }
            }
            button {
                padding: 42px 60px;
                text-align: center;
                background: #FEFEFE;
                border: 1px solid #DFDFDF;
                @include text (20px, 700, $dark, uppercase);
                @include font ($default);
                width: 100%;
            }
        }
    }
    &__content {
        padding: 64px 0 112px;
    }
    &__item {
        margin-bottom: 80px;
    }
    &__image {
        cursor: pointer;
        position: relative;
        padding: 39px 70px;
        margin-bottom: 22px;
        background: #FEFEFE;
        box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        border-radius: 10px;
        button {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            padding: 26px;
            @include text (36px, 700, white, capitalize);
            @include font ($default);
            border: 2px solid #FEFEFE;
            opacity: 0;
            visibility: hidden;
        }
        img {
            width: 100%;
        }
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 10px;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease;
        }
        &:hover {
            &:before, button {
                opacity: 1;
                visibility: visible
            }
        }
    }
    &__text {
        p, span {
            @include text (20px, 700, $dark, none);
            @include font ($default);
            line-height: 24px;
            display: inline-block;
        }
    }
}
.contact {
    padding-top: 55px;
    .section__title-box {
        h3 {
            @include text (38px, 700, $dark, capitalize);
        }
        margin-bottom: 41px;
    }
    &__content {
        padding-left: 55px;
        padding-top: 65px;
        display: flex;
        justify-content: center;
        padding-bottom: 142px;
    }
    &__map {
        width: 810px;
        margin-right: 30px;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    &__info {
        padding: 31px 95px 38px 71px;
        background: #FEFEFE;
        box-shadow: 5px 4px 30px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        ul {
            display: flex;
            flex-direction: column;
        }
        li {
            margin-bottom: 25px;
        }
        span, a {
            @include text (20px, 500, $dark, none);
            @include font ($default);
            display: inline-block;
            padding-left: 33px;
            max-width: 400px;
            line-height: 35px;
        }
        span, .contact__special {
            position: relative;
            &:before {
                content: '';
                background-image: url(../img/location-contact-1.svg);
                @include bci();
                width: 30px;
                height: 30px;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
}

.input {
    &__box {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        visibility: hidden;
    }
}

.outwindow-black {
    overflow: hidden;
    &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999999;
    }
}
.pagination {
    margin-top: 65px;
    display: flex;
    justify-content: center;
    ul {
        display: flex;
    }
    li {
        margin-right: 16px;
        &:last-child {
            margin-right: 0;
            button {
                border-radius: 0px 5px 5px 0;
            }
        }
        &:first-child {
            button {
                border-radius: 5px 0 0 5px;
            }            
        }
        &:last-child, &:first-child {
            button {
                background: #E3E3E6;
                filter: none;
            }
        }
    }
    button {
        padding: 15px 18px;
        border-radius: 5px;
        @include text (26px, 400, #787885, none);
        @include font ($default);
        background: #FEFEFE;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
        &.active {
            background: #D0F1FF;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.3);
        }
    }
    img {
        width: 28px;
        height: 100%;
    }
}

.tab {
    &-active {
        background: #E9E9E9;
        border: 1px solid #C9C9C9;
        box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.15);
    }
}
.hidden {
    display: none;
}
.modal__content-furniture {
    .modal__content-img {
        max-width: 100%;
        margin-right: 0;
    }
    .modal__content-full {
        p {
            @include text (20px, 500, $dark, none);
            @include font ($default);
            line-height: 30px;
            max-width: 566px;
        }
    }
    .furniture__text {
        margin-bottom: 36px;
    }
}
@import './media';
