@media (max-width: 1200px) {
    .container {
        max-width: 991px;
    }
    .header {
        .row {
            justify-content: center;
        }
        &__col-7 {
            @include col(9);
        }
        &__ul {
            a {
                font-size: 18px;
            }
        }
        &__input {
            &::placeholder {
                font-size: 18px;
            }
        }
    }
    .banner, .company, .catalog, .furniture, .contact {
        margin-top: 178px;
    } 
    .company {
        &__col-10 {
            @include col(12);
        }
    }  
    .banner {
        &__image {
            img {
                width: 100%;
                padding-bottom: 73px;
            }
        }
        .slick {
            &-dots {
                left: 40%;
            }
        }
    }
    .section {
        &__title-box {
            h3 {
                font-size: 30px;
            }
            p {
                font-size: 18px;
            }
        }
    }
    .materials {
        &__box {
            &-text {
                h3 {
                    font-size: 20px;
                }
            }
        }
    }
    .offer {
        .row {
            width: 100%;
        }
        &__item {
            width: 100%;
        }
        &__box {
            &-big {
                max-width: 50%;
                span {
                    font-size: 20px;
                }
            }
            &-small {
                justify-content: center;
                width: 150px;
                span {
                    font-size: 14px;
                }
                img {
                    width: 30%;
                }
            }
        }
        &__big-spc {
            max-width: 50%;
            width: auto;
            position: static;
            padding: 56px 117px 48px;
            text-align: center;
        }
    }
    .equipment {
        img {
            width: 100%;
        }
    }
    .advantages {
        &__box {
            p {
                font-size: 20px;
            }
        }
    }
    .work {
        &__col-10 {
            @include col(12);
        }
        &__image {
            &-spc {
                display: none;
            }
        }
        &__process-img {
            padding: 33px;
        }
    }
    .about {
        &__col-10  {
            @include col(12);
        }
        &__info {
            h4 {
                font-size: 30px;
            }
            p {
                font-size: 16px;
            }
            button {
                font-size: 16px;
            }
        }
    }
    .footer {
        .row {
            justify-content: center;
        }
        &__col-5 {
            @include col(7);
        }
        &__ul {
            margin-top: 30px;
            &-2 {
                justify-content: center;
            }
        }
        &__last {
            &-box {
                justify-content: center;
            }
        }
        &__img {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .catalog {
        &__content {
            justify-content: center !important;
        }
        &__col-10 {
            @include col(12);
        }
        &__col-3 {
            @include col(6);
            margin-bottom: 40px;
        }
        &__selector {
            padding-right: 58px;
            &:before {
                right: 26px;
            }
        }
    }
    .furniture {
        &__tab {
            &-box {
                ul {
                    flex-direction: column;
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .container {
        max-width: 768px;
    }
    .header {
        &__col-7 {
            @include col(12);
            margin: 15px 0;
        }
        &__input-box {
            margin-left: 38px;
        }
    }
    .company {
        padding-top: 85px;
        &__item {
            &-left {
                max-width: 100%;
                margin-right: 0;
            }
            &-right {
                max-width: 100%;
                &::before {
                    content: none;
                }
            }
            &-content {
                flex-direction: column;
            }
            &-top {
                span {
                    font-size: 18px;
                }
            }
        }
        &__text {
            &-box {
                flex-direction: column;
            }
            p {
                max-width: 100%;
                text-align: center;
            }
            &-left {
                max-width: 100%;
                margin-right: 0;
                margin-bottom: 30px;
            }
            &-right {
                max-width: 100%;
                margin-right: 0;
            }
        }
    }
    .banner {
        &__title-box {
            h2 {
                font-size: 34px;
                max-width: 50%;
            }
        }
        &__image {
            img {
                padding-bottom: 0;
            }
        }
        &__spc {
            margin-bottom: 105px !important;
        }
    }
    #banner__spc {
        margin-bottom: 72%;
    }
    .offer {
        &__col-10 {
            @include col(12);
        }
        &__item {
            flex-wrap: wrap;
        }
        &__box {
            &-small {
                width: 50%;
            }
        }
        #offer__box-none {
            display: none;
        }
        #offer__box-show {
            display: flex;
        }
        &__box {
            &-big {
                padding: 56px;
            }
        }
    }
    .advantages {
        &__col-10 {
            @include col(12);
        }
    }
    .about {
        &__box {
            flex-direction: column;
        }
        &__info {
            max-width: 100%;
        }
        &__video {
            margin-top: 30px;
            width: 100%;
        }
    }
    .footer {
        &__col-5 {
            @include col(9);
        }
        &__ul {
            &-2 {
                justify-content: space-between;
            }
        }
    }
    .catalog {
        &__col-3 {
            @include col(12);
        }
        .section__title-box {
            h3 {
                font-size: 30px;
            }
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                font-size: 25px;
            }
            button {
                font-size: 18px;
                max-width: 185px;
            }
        }
        &__selectors {
            flex-direction: column;
        }
        &__selector {
            margin-right: 0;
            transition: all 0.3s ease;
            &-content {
                border: none;
                background-color: transparent;
                padding-left: 29px;
            }
            span {
                font-size: 20px;
            }
            &:hover {
                .catalog__selector-content {
                    transform: translateY(0);
                    transition: all 0.3s ease;
                    position: static;
                }
                &:before {
                    top: 5%;
                }
            }
        }
    }
    .furniture {
        &__col-6 {
            @include col(12);
        }
    }
    .contact {
        &__content {
            flex-direction: column;
        }
        &__map {
            max-width: 810px;
            width: auto;
            margin-right: 0;
            margin-bottom: 40px;
            iframe {
                height: 489px;
            }
        }
    }
}
@media (max-width: 768px) {
    .container {
        max-width: 100%;
    }
    .header {
        &__col-7 {
            display: none;
        }
    }
    .burger {
        &-menu {
            display: block;
        }
    }
    .banner, .company, .catalog, .furniture, .contact {
        margin-top: 156px;
    }
    .banner {
        &__item {
            padding-bottom: 30px;
        }
    }
    .company {
        padding-top: 55px;
    }
    .materials {
        &__col-3 {
            @include col(6);
            margin-bottom: 40px;
        }
    }
    .header {
        .row {
            justify-content: space-between;
        }
        &__input-box {
            justify-content: flex-end;
        }
    }
    .advantages {
        &__coop {
            justify-content: center;
        }
        &__box {
            margin-bottom: 30px;
        }
    }
    .about {
        padding-top: 50px;
        &__info {
            margin-right: 0;
        }
    }
    .work {
        display: none;
    }
}
@media (max-width: 567px) {
    .banner {
        &__title-box {
            h2 {
                font-size: 28px;
            }
        }
        #banner__spc {
            margin-bottom: 86%;
        }
    }
    .footer {
        &__col-5 {
            @include col(10);
        }
        &__ul {
            &-1 {
                text-align: center;
                justify-content: space-between;
                li {
                    margin: 0 0 50px 0;
                }
                a {
                    font-size: 16px;
                }
            }
        }
        &__span {
            font-size: 16px;
        }
    }
    .materials {
        &__col-3 {
            @include col(12);
        }
    }
    .offer {
        &__box {
            &-big {
                padding: 35px;
                span {
                    font-size: 16px;
                }
            }
            &-small {
                span {
                    font-size: 12px;
                }
            }
        }
    }
    .section {
        &__title-box {
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .advantages {
        &__coop {
            flex-wrap: wrap;
        }
        &__box {
            p {
                font-size: 16px;
            }
        }
    }
    .about {
        &__info {
            h4 {
                font-size: 20px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .company {
        &__item {
            &-top {
                span {
                    font-size: 16px;
                }
            }
        }
    }
    .catalog {
        .section__title-box {
            h3 {
                font-size: 25px;
            }
        }
        &__selector {
            span {
                font-size: 18px;
            }
        }
        &__item {
            span {
                font-size: 20px;
            }
            button {
                font-size: 16px;
            }
        }
    }
    .contact {
        &__content {
            padding-left: 0;
        }
        .section__title-box {
            h3 {
                font-size: 25px;
            }
        }
    }
}
@media (max-width: 441px) {
    .banner {
        &__title-box {
            max-width: auto;
            margin-right: 23px;
        }
    }
    .header {
        &__col-3 {
            @include col(10);
            margin-top: 50px;
        }
    }
}