@mixin text($fz, $fw, $c, $tt) {
    font-size: $fz;
    font-weight: $fw;
    color: $c;
    text-transform: $tt;
}
@mixin col($col) {
    flex: 0 1 ($col / 12) * 100%;
    padding: 0 15px;
}
@mixin background($repeat, $size, $position) {
    background-repeat: $repeat;
    background-size: $size;
    background-position: $position;
}
@mixin bci() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
@mixin font ($font) {
    font-family: $font;
}
