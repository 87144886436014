@font-face {
    font-family: 'Gilroy Medium';
    src: url('Gilroy-Medium.eot');
    src: url('Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Medium.woff') format('woff'),
    url('Gilroy-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gilroy Bold';
    src: url('Gilroy-Bold.eot');
    src: url('Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Bold.woff') format('woff'),
    url('Gilroy-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}